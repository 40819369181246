import * as i0 from '@angular/core';
import { InjectionToken, EventEmitter, Directive, Inject, Input, Output, NgModule } from '@angular/core';
import { Subscription, fromEventPattern } from 'rxjs';
import * as i1 from '@ng-maps/core';
const NG_MAPS_PLACES_DEFAULT_CONFIGURATION = new InjectionToken('NgMapsPlacesDefaultConfiguration', {
  providedIn: 'root',
  factory: () => ({})
});

/**
 * @example
 * <input [mapAutocomplete]='options' (placeResult)='onPlacesResult($event)' />
 */
class NgMapsAutocompleteDirective {
  constructor(element, mapsAPILoader, defaultConfig, _zone) {
    this.element = element;
    this.mapsAPILoader = mapsAPILoader;
    this.defaultConfig = defaultConfig;
    this._zone = _zone;
    /**
     * This event is fired on selection of an element from the autocomplete list.
     * The event contains a PlaceResult from GoogleMapsAPI
     * https://developers.google.com/maps/documentation/javascript/reference/3.exp/places-service#PlaceResult
     */
    this.placeResult = new EventEmitter();
    /**
     * This event is fired on selection of an element from the autocomplete list.
     * The event contains a LatLngBounds from GoogleMapsAPI
     * https://developers.google.com/maps/documentation/javascript/reference/3.exp/coordinates#LatLngBounds
     */
    this.bounds = new EventEmitter();
    this.subscription = new Subscription();
  }
  /** @internal */
  ngOnInit() {
    if (typeof this.config === 'undefined') {
      this.config = {
        types: ['address'],
        ...(this.defaultConfig?.autocomplete ?? {})
      };
    } else {
      this.config = {
        types: ['address'],
        ...(this.defaultConfig?.autocomplete ?? {}),
        ...this.config
      };
    }
    if (this.element.nativeElement instanceof HTMLInputElement) {
      this.init();
    } else {
      throw new Error('Directive can only be applied to an HTMLInputElement');
    }
  }
  /** @internal */
  async init() {
    await this.mapsAPILoader.load();
    this.autocomplete = new google.maps.places.Autocomplete(this.element.nativeElement, this.config);
    this.subscription.add(fromEventPattern(handler => this.addHandler(handler), () => this.removeHandler()).subscribe({
      next: () => {
        this.placeResult.emit(this.autocomplete.getPlace());
        this.bounds.emit(this.autocomplete.getBounds());
      }
    }));
  }
  /** @internal */
  ngOnChanges(changes) {
    if (typeof changes.config !== 'undefined' && !changes.config.firstChange) {
      const config = changes.config.currentValue;
      if (typeof config.bounds !== 'undefined') {
        this.autocomplete?.setBounds(config.bounds);
      }
      if (typeof config.componentRestrictions !== 'undefined') {
        this.autocomplete?.setComponentRestrictions(config.componentRestrictions);
      }
      if (typeof config.types !== 'undefined') {
        this.autocomplete?.setTypes(config.types);
      }
    }
  }
  /** @internal */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /** @internal */
  addHandler(handler) {
    return this.autocomplete?.addListener('place_changed', () => this._zone.run(handler));
  }
  /** @internal */
  removeHandler() {
    this.autocomplete?.unbindAll();
  }
  static {
    this.ɵfac = function NgMapsAutocompleteDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgMapsAutocompleteDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.MapsAPILoader), i0.ɵɵdirectiveInject(NG_MAPS_PLACES_DEFAULT_CONFIGURATION), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgMapsAutocompleteDirective,
      selectors: [["", "mapAutocomplete", ""]],
      inputs: {
        config: [0, "mapAutocomplete", "config"]
      },
      outputs: {
        placeResult: "placeResult",
        bounds: "bounds"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgMapsAutocompleteDirective, [{
    type: Directive,
    args: [{
      selector: '[mapAutocomplete]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1.MapsAPILoader
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NG_MAPS_PLACES_DEFAULT_CONFIGURATION]
    }]
  }, {
    type: i0.NgZone
  }], {
    config: [{
      type: Input,
      args: ['mapAutocomplete']
    }],
    placeResult: [{
      type: Output
    }],
    bounds: [{
      type: Output
    }]
  });
})();

/**
 * @example
 * <input [mapAutocomplete]='options' (placeResult)='onPlacesResult($event)' />
 */
class NgMapsSearchBoxDirective {
  constructor(element, mapsAPILoader, defaultConfig, _zone) {
    this.element = element;
    this.mapsAPILoader = mapsAPILoader;
    this.defaultConfig = defaultConfig;
    this._zone = _zone;
    /**
     * This event is fired on selection of an element from the autocomplete list.
     * The event contains a PlaceResult from GoogleMapsAPI
     * https://developers.google.com/maps/documentation/javascript/reference/3.exp/places-service#PlaceResult
     */
    this.placeResult = new EventEmitter();
    /**
     * This event is fired on selection of an element from the autocomplete list.
     * The event contains a LatLngBounds from GoogleMapsAPI
     * https://developers.google.com/maps/documentation/javascript/reference/3.exp/coordinates#LatLngBounds
     */
    this.bounds = new EventEmitter();
    this.subscription = new Subscription();
  }
  /** @internal */
  ngOnInit() {
    if (typeof this.config === 'undefined') {
      this.config = this.defaultConfig?.searchBox;
    } else {
      this.config = {
        ...(this.defaultConfig?.searchBox ?? {}),
        ...this.config
      };
    }
    if (this.element.nativeElement instanceof HTMLInputElement) {
      this.init();
    } else {
      throw new Error('Directive can only be applied to an HTMLInputElement');
    }
  }
  /** @internal */
  async init() {
    await this.mapsAPILoader.load();
    this.searchBox = new google.maps.places.SearchBox(this.element.nativeElement, this.config);
    this.subscription.add(fromEventPattern(handler => this.addHandler(handler), () => this.removeHandler()).subscribe({
      next: () => {
        this.placeResult.emit(this.searchBox.getPlaces());
        this.bounds.emit(this.searchBox.getBounds());
      }
    }));
  }
  /** @internal */
  ngOnChanges(changes) {
    if (typeof changes.config !== 'undefined' && !changes.config.firstChange) {
      const config = changes.config.currentValue;
      if (typeof config.bounds !== 'undefined' && this.searchBox) {
        this.searchBox.setBounds(config.bounds);
      }
    }
  }
  /** @internal */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  /** @internal */
  addHandler(handler) {
    return this.searchBox?.addListener('places_changed', () => this._zone.run(handler));
  }
  /** @internal */
  removeHandler() {
    this.searchBox?.unbindAll();
  }
  static {
    this.ɵfac = function NgMapsSearchBoxDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgMapsSearchBoxDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.MapsAPILoader), i0.ɵɵdirectiveInject(NG_MAPS_PLACES_DEFAULT_CONFIGURATION), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgMapsSearchBoxDirective,
      selectors: [["", "mapSearchBox", ""]],
      inputs: {
        config: [0, "mapSearchBox", "config"]
      },
      outputs: {
        placeResult: "placeResult",
        bounds: "bounds"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgMapsSearchBoxDirective, [{
    type: Directive,
    args: [{
      selector: '[mapSearchBox]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i1.MapsAPILoader
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NG_MAPS_PLACES_DEFAULT_CONFIGURATION]
    }]
  }, {
    type: i0.NgZone
  }], {
    config: [{
      type: Input,
      args: ['mapSearchBox']
    }],
    placeResult: [{
      type: Output
    }],
    bounds: [{
      type: Output
    }]
  });
})();

/**
 * @internal
 */
function placesDirectives() {
  return [NgMapsAutocompleteDirective, NgMapsSearchBoxDirective];
}
class NgMapsPlacesModule {
  /**
   * configure the NgMapsPlacesModule with a value
   * @param config
   */
  static forRoot(config) {
    return {
      ngModule: NgMapsPlacesModule,
      providers: [{
        provide: NG_MAPS_PLACES_DEFAULT_CONFIGURATION,
        useValue: config
      }]
    };
  }
  /**
   * configure the NgMapsPlacesModule with a factory
   * @param factory
   * @param deps
   */
  static forRootFactory(factory, deps) {
    return {
      ngModule: NgMapsPlacesModule,
      providers: [{
        provide: NG_MAPS_PLACES_DEFAULT_CONFIGURATION,
        useFactory: factory,
        deps
      }]
    };
  }
  static {
    this.ɵfac = function NgMapsPlacesModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgMapsPlacesModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgMapsPlacesModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgMapsPlacesModule, [{
    type: NgModule,
    args: [{
      declarations: placesDirectives(),
      exports: placesDirectives()
    }]
  }], null, null);
})();

/*
 * Public API Surface of places
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NG_MAPS_PLACES_DEFAULT_CONFIGURATION, NgMapsAutocompleteDirective, NgMapsPlacesModule, NgMapsSearchBoxDirective, placesDirectives };
