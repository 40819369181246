import { Directive, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ClassroomService } from '../classroom.service';
import { GameProgressSelectableColumn } from '../game-progress-selectable-column';
import { ProgressionTableService } from '../progression-table.service';
import  moment from "moment-timezone";
import { GameProgressAppActivity, GameProgressListInfo, WordListSubType, WordListType } from '@applogic/model';

export class Chip {
    listId: string;
    gameNo?: number;
    pos?: number;
    attempts: number;
    levels?: any[];
    color_code: string;
    isPlay?: boolean;
    name: string;
    completedlevels: any[];
    type: WordListType;
    subtype: WordListSubType;
    label?: string;
    completedlevelssublevel?: boolean;
    season?: number;

    public setList(list: GameProgressListInfo) {
        this.listId = list._id;
        this.name = list.name;
        this.type = list.type;
        this.subtype = list.subtype;
        this.season = list.season;
        this.pos = list.pos;
        this.levels = list.level;
    }

    public setGame(game: GameProgressAppActivity) {
        this.gameNo = game.no;
        this.name = game.name;
    }
};

/*@Component({
    template: ''
})*/
@Directive()
export abstract class BaseGameProgressComponent implements OnInit {
    @Input() classroomId: string;
    @Input() currentGame: any;
    @Input() currentLanguageType: any;
    @Input() isSelectedDate: any;
    @Input() classStudent: any;

    public currentAppGameCode: string = "";
    protected classroomService: ClassroomService;
    protected localServices: LocalStorageService;
    protected progressTableService: ProgressionTableService;

    public seasons: number[] = [];

    public selectedSeason: number = undefined;

    public isloading: number = 0;
    public removable: boolean = true;
    public selectable: boolean = true;

    columns: GameProgressSelectableColumn[] = [];

    startDate: any;
    EndDate: any;
    dateTimeRange: any;
    currentDate: any;

    selectedColumns: Array<GameProgressSelectableColumn> = [];
    selectedColumnsKey: string[];

    count: number = 5; // Number of items to display per page.

    appWordLists: GameProgressListInfo[] = [];
    homeworkWordLists: GameProgressListInfo[] = [];
    appActivities: GameProgressAppActivity[] = [];

    dailyColumns: GameProgressSelectableColumn[] = [];
    dailyColumnsKey: string[] = [];

    translations: {[langCode: string]: {[key:string]: string}} = {};

    constructor(injector: Injector) {
        this.classroomService = injector.get(ClassroomService);
        this.localServices = injector.get(LocalStorageService);
        this.progressTableService = injector.get(ProgressionTableService);
    }

    ngOnInit(): void {
        const PAGE_SIZE_KEY = this.currentAppGameCode + "/table/page/size";
        const PAGE_SIZE = this.localServices.get(PAGE_SIZE_KEY);
        if (PAGE_SIZE != null) {
            this.count = PAGE_SIZE
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["isSelectedDate"]) {
            this.columns = [];
        }
    }

    /**
     * Set the date range to current date with the default range.
     */
    protected setCurrentDate(): void {
        this.dateTimeRange = "";
        this.unselectAllColumns();
        const dateTo = moment();
        const dateFrom = moment().subtract(7, "d");
        this.dateTimeRange = [dateFrom.toISOString(), dateTo.toISOString()];
        this.startDate = dateTo;
        this.EndDate = dateFrom;
    }

    /**
     * Update the columns.
     */
    updateColumns() {
        this.unselectAllColumns();

        this.columns.forEach((column) => {
            if (column.header) {
                if (column.isSelectedValue) {
                    this.selectColumn(column);
                }
                return;
            }

            if (this.currentAppGameCode == "MMO") {
                if (column.isSelectedValue == true) {
                    this.selectColumn(column);
                }
            } else if (this.currentAppGameCode == "MSM") {
                if (column.isSelectedValue == true) {
                    this.selectColumn(column);
                }
            }
        });
    }

    /**
     * Refresh the progress table.
     */
    refreshAllData() {
        return new Promise((resolve, reject) => {
            this.updateGameActivities().then(() => {
                return this.updateAppWordList();
            }).then(() => {
                return this.updateHomeworkWordList();
            }).then(() => {
                if (this.isSelectedDate == true) {
                    this.updateProgressByDate();
                } else {
                    this.updateProgressByActivity();
                }
                resolve(true);
            }).catch((ex) => {
                console.error(ex);
                reject(ex);
            });
        });
    }

    /**
     * Update the app word lists. (this.appWordLists)
     */
    updateAppWordList() {
        return new Promise((resolve, reject) => {
            const LOWERCASE = this.currentAppGameCode.toLowerCase();
            this.progressTableService
                .getAppWordLists(LOWERCASE, this.currentLanguageType)
                .then((response) => {
                    this.appWordLists = response;

                    this.seasons = [];
                    this.appWordLists.forEach((list) => {
                        if(list.season && (this.seasons.indexOf(list.season) == -1)) {
                            this.seasons.push(list.season);
                        }
                    });
                    this.seasons.sort(s => s);

                    this.onAppWordListsUpdate();
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Update the homework word lists. (this.homeworkWordLists)
     */
    updateHomeworkWordList() {
        return new Promise((resolve, reject) => {
            if(this.currentAppGameCode == "MMO") {
                let result: GameProgressListInfo[] = [];
                resolve(result);
                return;
            }
            this.progressTableService
                .getHomeworkWordLists(this.classroomId, this.currentLanguageType)
                .then((response) => {
                    this.homeworkWordLists = response;
                    this.onHomeworkWordListsUpdate();
                    resolve(true);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Update the app activities. (this.appActivities)
     */
    updateGameActivities() {
        return new Promise((resolve, reject) => {
            const LOWERCASE = this.currentAppGameCode.toLowerCase();

            this.progressTableService
                .getAppActivities(LOWERCASE, this.currentLanguageType)
                .then((response) => {
                    this.appActivities = response;
                    this.onAppActivitiesUpdate();
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    /**
     * Update the progress table to display progression by activity (or wordlist).
     */
    abstract updateProgressByActivity();

    /**
     * Update the progress table to display progression by date
     */
    abstract updateProgressByDate();

    /**
     * Triggered when the app words lists (this.appWordLists) is updated.
     */
    protected onAppWordListsUpdate(): void {

    }

    /**
     * Triggered when the homeworks words lists (this.homeworkWordLists) is updated.
     */
    protected onHomeworkWordListsUpdate(): void {

    }



    /**
     * Triggered when the app activities list (this.appActivities) is updated.
     */
    protected onAppActivitiesUpdate(): void {

    }

    /**
     * Triggered when the date range changed.
     */
    onDateRangeChange(dateRange: any) {
        this.startDate = dateRange[1];
        this.EndDate = dateRange[0];
        this.updateProgressByDate();
    }

    /**
     * Triggered when pagination changed.
     */
    onPaginateChange(event) {
        const PAGE_SIZE_KEY = this.currentAppGameCode + "/table/page/size";
        this.count = event.pageSize;
        this.localServices.set(PAGE_SIZE_KEY, event.pageSize)
    }

    public isNotString(data): boolean {
        if (typeof data == "string") {
            return false;
        } else {
            return true;
        }
    }

    protected setLoading(state: boolean): void {
        if (state) {
            this.isloading++;
        } else {
            this.isloading--;
        }

        // Useful when debugging problem with the loading bar.
        // console.error("isloading: " + this.isloading);
    }

    protected selectColumn(column: GameProgressSelectableColumn) {
        if (this.selectedColumns.indexOf(column) == -1) {
            this.selectedColumns.push(column);

            // Keep the columns sorted.
            this.selectedColumns = this.selectedColumns.sort(function (a, b) {
                var x = a.order < b.order ? -1 : 1;
                return x;
            });

            this.selectedColumnsKey = this.selectedColumns.map((c) => c.key);

            // The array sort doesn't create a new array. It is necessary to create
            // a new array for the UI to update.
            this.selectedColumns = [...this.selectedColumns];
        }
    }

    protected unselectColumn(column: GameProgressSelectableColumn) {
        let idx: number = this.selectedColumns.indexOf(column);
        if (idx != -1) {
            this.selectedColumns.splice(idx, 1);
            this.selectedColumnsKey.splice(idx, 1);
            this.selectedColumns = [...this.selectedColumns];
        }
    }

    protected unselectAllColumns() {
        this.selectedColumns = [];
        this.selectedColumnsKey = [];
    }

    protected refreshSelectedColumns() {
        this.unselectAllColumns();
        this.columns.forEach((column) => {
            if (column.isSelectedValue == true) {
                this.selectColumn(column);
            }
        });
    }

    onChipDataAdded(chip: Chip) {
        if(!chip.season) return;

        // Select the most recent season by default.
        if( (this.selectedSeason == undefined) || (chip.season > this.selectedSeason) ) {
            this.selectedSeason = chip.season;
        }
    }

    onSeasonTabChanged(event: any) {
        
        let clickedIndex = event.index;
        
        this.selectedSeason = this.seasons[clickedIndex];
    }

    filterChipsBySeason(chips: Chip[], selectedSeason: number) {
        return chips.filter(d => !selectedSeason || (!d.season && (selectedSeason==1)) || (d.season == selectedSeason));
    }
}
