import { Component, Injector, Input } from '@angular/core';
import { CommonColumnType } from 'src/app/core/common-list/common-list-column';
import { CommonListComponent } from 'src/app/core/common-list/common-list.component';
import  moment from "moment-timezone";
import { MMOChip, MMODatesTableRow } from '../mmo-game-progress.model';
import { MMOGameProgressUtils } from '../mmo-game-progress.utils';
import { DatePipe } from '@angular/common';


@Component({
    selector: 'app-mmo-date-list',
    templateUrl: './mmo-date-list.component.html',
    styleUrls: ['../../base-game-progress/base-game-progress.component.scss', './mmo-date-list.component.scss']
})
export class MmoDateListComponent extends CommonListComponent<MMODatesTableRow> {

    MMOGameProgressUtils = MMOGameProgressUtils;

    startDate: string;
    stopDate: string;
    dateKeys: string[] = [];

    @Input()
    selectedSeason: number = undefined;

    currentChip: MMOChip;
    
    constructor(injector: Injector, private datePipe: DatePipe) {
        super(injector);
        
        this.pageSizeOptions = [5, 10, 25, 50, 100];
        this.pageSize = 5;
        this.pageSizeDefault = 5;

        this.doInitialRefresh = false;

        this.rendererOptions.tableContainerClasses = ['game-progress-list', 'mat-elevation-z8'];
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    setDateRange(startDate: string, stopDate: string) {
        this.startDate = startDate;
        this.stopDate = stopDate;
        this.initColumns();
    }

    setProgressData(rows: MMODatesTableRow[], selectedSeason: number) {
        this.selectedSeason = selectedSeason;
        this.setPreloadItems(rows);
    }

    initColumns() {
        this.initPersistentKey("");

        this.addColumn($localize`:@@common-student-name: Student’s name `, "student_name", false, true, CommonColumnType.Text, {
            fixedWidth: "200px"
        });


        this.dateKeys = [];
        let currentDate = moment(this.startDate);
        let endDate = moment(this.stopDate);
        while (currentDate <= endDate) {
            const dateKey = moment(currentDate).format("D/M/YY");
            const dateLabel = moment(currentDate).format("YYYY-MM-DD");

            this.addColumn(dateLabel, dateKey, false, true, CommonColumnType.Template, {
                fixedWidth: "150px",
                isSortable: false,
                tooltip: this.datePipe.transform(dateLabel, 'EEEE, MMMM d, y')
            });
            
            currentDate = moment(currentDate).add(1, "days");
            this.dateKeys.push(dateKey);
        }

        this.updateColumns(true);
    }

    getItems(start: number, count: number, sort?: string, search?: any) {
        
    }

    setCurrentChip(chip: MMOChip) {     
        setTimeout(() => {
            this.currentChip = chip;
        }, 200);
    }
}
